import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import LayoutContext from "../../../components/LayoutContext"
import BetterWithRealtor from "../../../components/generator/BetterWithRealtor"

const BesserMitMakler = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      hide: true,
      stopTrack: true,
    }))
    return function unmount() {
      setLayout(state => ({
        ...state,
        hide: false,
        stopTrack: false,
      }))
    }
  }, [])

  return (
    <>
      <Helmet>
        <script src="/iframeResizer.contentWindow.min.js" />
      </Helmet>
      <BetterWithRealtor
        customTitle={false /*`Wer ist unser Top-Makler in Ihrer Region?`*/}
        isIframe={true}
      />
    </>
  )
}

export default BesserMitMakler
